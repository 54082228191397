const { Network } = require("alchemy-sdk");

export const MONONOFUT_CHAINS = [Network.ETH_MAINNET, Network.MATIC_MAINNET];
export const NFTTYPE_CHAR = 'ch';
export const NFTTYPE_PFP = 'pf';
export const NFTTYPE_EQUIP = 'eq';

export const MONONOFU_CHARACTERS = [
{
	nftid:'001',
	name: '月兎 -Tsukinoto-',
	title: 'MonoNoFuT_SamuraiPunks_#001',
	desc: 'MoonBit社のサイボーグ部隊総隊長',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gae/n5NMZe8Eqgqon9KztWAvLxbDaFqwwaQiTHpupnmPts_z3Y8xNIlq6Ub9WwwiPn6HxWvo7rSEQTz2sb2GRfpYJxqKpvP3Ag3pSLg5Sg',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172637180127084545',
	pfpimg:'https://i.seadn.io/gae/QXWlzuIVrouxCKnLQz195v6CEqTzsjLf0zF5rseeEEljqCM5HepKHdRj0ycOve6lDP5VaO1GPZMaA5gc1QKB7mibnIfOR1gT5ZuDJA',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'mnft_tsukinoto',
	relate:['001pfp', 'eq001'],
	type: NFTTYPE_CHAR
},
{
	nftid:'001pfp',
	name: '月兎 -Tsukinoto- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#001_PFP',
	desc: 'MoonBit社のサイボーグ部隊総隊長のPFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gae/QXWlzuIVrouxCKnLQz195v6CEqTzsjLf0zF5rseeEEljqCM5HepKHdRj0ycOve6lDP5VaO1GPZMaA5gc1QKB7mibnIfOR1gT5ZuDJA',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172636080615456769',
	pfpimg:'https://i.seadn.io/gae/QXWlzuIVrouxCKnLQz195v6CEqTzsjLf0zF5rseeEEljqCM5HepKHdRj0ycOve6lDP5VaO1GPZMaA5gc1QKB7mibnIfOR1gT5ZuDJA',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'mnft_tsukinoto',
	relate:[],
	type:NFTTYPE_PFP
},


{
	nftid:'003',
	name: '神代 -Kamiyo-',
	title: 'MonoNoFuT_SamuraiPunks_#003',
	desc: 'クリプト業界の天才ハッカー',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gae/eP2VKvHK3CJ1nMQThCf7VLc4gl5-c6Vcd_vQA3CcR1qGRUYxwWr0TzgtgJd98A2F0f3spWDzCtkczYmGcLSC41-N26cZboINWTYMuA',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172639379150340097',
	pfpimg:'https://i.seadn.io/gae/Hp036sVk1J_Z7qyxK5kGG82gwZCVULZWfFo98RIHA7yjwie1Gr8MI7K-3MXMMsihu3W-Z9vJSKe4RHI5sdTjFmuOD5BNuJcNe9ZBVg',
	storyurl:'https://note.com/heppokopu/n/ndc5086943643',
	company:'MoonBit',
	oneOn1:'mnft_kamiyo',
	relate:['003pfp', 'eq001'],
	type:NFTTYPE_CHAR
},
{
	nftid:'003pfp',
	name: '神代 -Kamiyo- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#003',
	desc: 'クリプト業界の天才ハッカー PFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gae/Hp036sVk1J_Z7qyxK5kGG82gwZCVULZWfFo98RIHA7yjwie1Gr8MI7K-3MXMMsihu3W-Z9vJSKe4RHI5sdTjFmuOD5BNuJcNe9ZBVg',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172640478661967873',
	pfpimg:'https://i.seadn.io/gae/Hp036sVk1J_Z7qyxK5kGG82gwZCVULZWfFo98RIHA7yjwie1Gr8MI7K-3MXMMsihu3W-Z9vJSKe4RHI5sdTjFmuOD5BNuJcNe9ZBVg',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'mnft_kamiyo',
	relate:[],
	type:NFTTYPE_PFP
},

{
	nftid:'004',
	name: '雪華 -Yukika-',
	title: 'MonoNoFuT_SamuraiPunks_#004',
	desc: 'Moonbit社のオールラウンダー',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/eaaaa04518ffa84f19c0185e3f257b90.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172641578173595649',
	pfpimg:'https://i.seadn.io/gcs/files/00b530260b58c6a9423613300e45647f.png',
	storyurl:'https://note.com/heppokopu/n/n73ac87a64a55',
	company:'MoonBit',
	oneOn1:'',
	relate:['004pfp', 'eq001'],
	type:NFTTYPE_CHAR
},
{
	nftid:'004pfp',
	name: '雪華 -Yukika- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#004_PFP',
	desc: 'Moonbit社のオールラウンダー PFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/00b530260b58c6a9423613300e45647f.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172642677685223425',
	pfpimg:'https://i.seadn.io/gcs/files/00b530260b58c6a9423613300e45647f.png',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},

{
	nftid:'005',
	name: '舞蝶 -Ageha-',
	title: 'MonoNoFuT_SamuraiPunks_#005',
	desc: '業界屈指の近接戦闘特化型サイボーグ',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/7802bb934f364b7bd2b41cca8b1f0f7f.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172643777196851201',
	pfpimg:'https://i.seadn.io/gcs/files/d0d009ca200747c812e553413dcc8241.png',
	storyurl:'https://note.com/heppokopu/n/n7bdef6ca8c42',
	company:'MoonBit',
	oneOn1:'mnft_ageha',
	relate:['005pfp', 'eq001', '006v1', '006v2'],
	type:NFTTYPE_CHAR
},
{
	nftid:'005pfp',
	name: '舞蝶 -Ageha- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#005_PFP',
	desc: '業界屈指の近接戦闘特化型サイボーグ PFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/d0d009ca200747c812e553413dcc8241.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172644876708478977',
	pfpimg:'https://i.seadn.io/gcs/files/d0d009ca200747c812e553413dcc8241.png',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'mnft_ageha',
	relate:[],
	type:NFTTYPE_PFP
},

{
	nftid:'006v1',
	name: '叶香 -Kanoka-',
	title: 'MonoNoFuT_SamuraiPunks_#006v1.0',
	desc: '記憶をブロックチェーンにロックされた少女',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/9e165d0deae0dffadb1c29265f1c8599.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172645976220106753',
	pfpimg:'https://i.seadn.io/gcs/files/0a65a23c0de797e2255a7d401bd73a12.png',
	storyurl:'https://note.com/heppokopu/n/necd99aca31b3',
	company:'FloatingBits',
	oneOn1:'',
	relate:['006v1pfp', 'eq001'],
	type:NFTTYPE_CHAR
},
{
	nftid:'006v1pfp',
	name: '叶香 -Kanoka- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#006v1_PFP',
	desc: '記憶をブロックチェーンにロックされた少女 PFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/0a65a23c0de797e2255a7d401bd73a12.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172647075731734529',
	pfpimg:'https://i.seadn.io/s/raw/files/0a65a23c0de797e2255a7d401bd73a12.png',
	storyurl:'',
	company:'FloatingBits',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},



{
	nftid:'006v2',
	name: '琴葉 -Kotoha-',
	title: 'MonoNoFuT_SamuraiPunks_#006v2.0',
	desc: 'ブロックチェーンから記憶を取り戻した少女',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/gcs/files/0667199710b426591f018abba7323a28.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172648175243362305',
	pfpimg:'https://i.seadn.io/gcs/files/1ae8c09bee483ba04173f202de3f0357.png',
	storyurl:'https://note.com/heppokopu/n/nd45c62a76225',
	company:'MoonBit',
	oneOn1:'',
	relate:['006v2pfp', 'eq001'],
	type:NFTTYPE_CHAR
},
{
	nftid:'006v2pfp',
	name: '琴葉 -Kotoha- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#006v2_PFP',
	desc: 'ブロックチェーンから記憶を取り戻した少女 PFP',
	contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/1ae8c09bee483ba04173f202de3f0357.png',
	shop:'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111229519723867166216722171907959176443727552862407997315552172649274754990081',
	pfpimg:'https://i.seadn.io/s/raw/files/1ae8c09bee483ba04173f202de3f0357.png',
	storyurl:'',
	company:'MoonBit',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},


{
	nftid:'007v1',
	name: '那由多v1 -Nayutav1-',
	title: 'MonoNoFuT_SamuraiPunks_#007v1',
	desc: '業界トップクラスの義体使い（通常モード）',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.ETH_MAINNET,
	img: 'https://d1updk8hq321rl.cloudfront.net/optimized/888fa2dc701e16ec30b355f1d19c0b87d1b12192018308f15e8f1b3b49ac79de/w_1024.webp',
	shop:'https://app.manifold.xyz/c/mononofut007v1',
	pfpimg:'https://d1updk8hq321rl.cloudfront.net/optimized/888fa2dc701e16ec30b355f1d19c0b87d1b12192018308f15e8f1b3b49ac79de/w_1024.webp',
	storyurl:'https://note.com/heppokopu/n/n3f8740714517',
	company:'FloatingBits',
	oneOn1:'mnft_nayuta',
	relate:['eq001', 'eq002'],
	type:NFTTYPE_CHAR
},

{
	nftid:'007v2',
	name: '那由多v2 -Nayutav2-',
	title: 'MonoNoFuT_SamuraiPunks_#007v2',
	desc: '業界トップクラスの義体使い（殲滅モード）',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.ETH_MAINNET,
	img: 'https://d1updk8hq321rl.cloudfront.net/optimized/b47904f35fecfacf4c23c047591df6c0b07947eebb3e74f562bd6e71f6d35974/w_1024.webp',
	shop:'https://app.manifold.xyz/c/mononofut007v2',
	pfpimg:'https://d1updk8hq321rl.cloudfront.net/optimized/b47904f35fecfacf4c23c047591df6c0b07947eebb3e74f562bd6e71f6d35974/w_1024.webp',
	storyurl:'https://note.com/heppokopu/n/n05e4215cab12',
	company:'FloatingBits',
	oneOn1:'mnft_nayuta',
	relate:['eq001', 'eq002'],
	type:NFTTYPE_CHAR
},

{
	nftid:'008v1',
	name: '洛叉v1 -Rakusha-',
	title: 'MonoNoFuT_SamuraiPunks_#008v1',
	desc: 'FloatingBits社の慈善事業部リーダー（夏仕様）',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.ETH_MAINNET,
	img: 'https://d1updk8hq321rl.cloudfront.net/optimized/0ea1b375dbd867a6fe85ded56a1e97900af5f9a5c3b990392a9cfaaaba4870c0/w_1024.webp',
	shop:'https://app.manifold.xyz/c/projectmononofut008v1',
	pfpimg:'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	storyurl:'https://note.com/heppokopu/n/n05e4215cab12',
	company:'FloatingBits',
	oneOn1:'',
	relate:['008v1pfp', 'eq001', 'eq003'],
	type:NFTTYPE_CHAR
},
{
	nftid:'008v1pfp',
	name: '洛叉v1 -Rakusha- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#008v1_PFP',
	desc: 'FloatingBits社の慈善事業部リーダー（夏仕様）PFP',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	shop:'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/67550670876665638327049477160388918657639790931114759609867250196066009612298',
	pfpimg:'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	storyurl:'',
	company:'FloatingBits',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},


{
	nftid:'008v2',
	name: '洛叉v2 -Rakusha-',
	title: 'MonoNoFuT_SamuraiPunks_#008v1',
	desc: 'FloatingBits社の慈善事業部リーダー（制服仕様）',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.ETH_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/2ba9f26579a714ad00ec0984d255634c.png',
	shop:'https://app.manifold.xyz/c/mononofut008v2',
	pfpimg:'https://i.seadn.io/s/raw/files/2ba9f26579a714ad00ec0984d255634c.png',
	storyurl:'https://note.com/heppokopu/n/n05e4215cab12',
	company:'FloatingBits',
	oneOn1:'',
	relate:['008v2pfp', 'eq001', 'eq003'],
	type:NFTTYPE_CHAR
},
{
	nftid:'008v2pfp',
	name: '洛叉v2 -Rakusha- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#008v2_PFP',
	desc: 'FloatingBits社の慈善事業部リーダー（制服仕様）',
	contract: '0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/2ba9f26579a714ad00ec0984d255634c.png',
	shop:'https://opensea.io/assets/matic/0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f/2',
	pfpimg:'https://i.seadn.io/s/raw/files/2ba9f26579a714ad00ec0984d255634c.png',
	storyurl:'',
	company:'FloatingBits',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},

{
	nftid:'009v1',
	name: '焔v1 -Homura-',
	title: 'MonoNoFuT_SamuraiPunks_#009v1',
	desc: 'FloatingBits社情報局局長',
	contract: '0x6CC6f8aDC123e7d50406F23c7a22E9DDfD10E7b0',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	shop:'/shop',
	pfpimg:'https://i.seadn.io/s/raw/files/883e84133a12e7970e94b8bf6872f306.png',
	storyurl:'',
	company:'FloatingBits',
	oneOn1:'mnft_homura',
	relate:['009v1pfp', 'eq001', 'eq004'],
	type:NFTTYPE_CHAR
},
{
	nftid:'009v1pfp',
	name: '焔v1 -Rakusha- PFP',
	title: 'MonoNoFuT_SamuraiPunks_#009v1_PFP',
	desc: 'FloatingBits社情報局局長 PFP',
	contract: '0x2477b682fda8879e813aeaa2a5c05654bde3747b',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	shop:'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/67550670876665638327049477160388918657639790931114759609867250196066009612298',
	pfpimg:'https://i.seadn.io/s/raw/files/3996c740a42b83bbce276aceaae8459d.png',
	storyurl:'',
	company:'FloatingBits',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_PFP
},


{
	nftid:'eq001',
	name: 'Equipment001',
	title: 'MonoNoFuT_SamuraiPunks_Equip#001',
	desc: 'アンダーアーマー。一般的なサイボーグが身に着ける。',
	contract: '0x2953399124f0cbb46d2cbacd8a89cf0599974963',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/gcs/files/eb8752f5d030a1ea9a2ea64aa8fa5f5b.png',
	shop:'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/111229519723867166216722171907959176443727552862407997315552172643777196851300',
	pfpimg:'https://i.seadn.io/gcs/files/eb8752f5d030a1ea9a2ea64aa8fa5f5b.png',
	storyurl:'',
	company:'All',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_EQUIP
},
{
	nftid:'eq002',
	name: 'Equipment002',
	title: 'MonoNoFuT_SamuraiPunks_Equip#002',
	desc: 'サイボーグ用のハンドレールガン。',
	contract: '0x2953399124f0cbb46d2cbacd8a89cf0599974963',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/f3b70b35b51c5eaf0a7e4d52d9a223a9.png',
	shop:'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/111229519723867166216722171907959176443727552862407997315552172645976220106852',
	pfpimg:'https://i.seadn.io/s/raw/files/f3b70b35b51c5eaf0a7e4d52d9a223a9.png',
	storyurl:'',
	company:'All',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_EQUIP
},
{
	nftid:'eq003',
	name: 'Equipment003',
	title: 'MonoNoFuT_SamuraiPunks_Equip#003',
	desc: 'サイボーグ用のハンドガン。',
	contract: '0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/09f5a3f63ee533cfc46ce83f45383a29.png',
	shop:'https://opensea.io/assets/matic/0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f/1',
	pfpimg:'https://i.seadn.io/s/raw/files/09f5a3f63ee533cfc46ce83f45383a29.png',
	storyurl:'',
	company:'All',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_EQUIP
},
{
	nftid:'eq004',
	name: 'Equipment004',
	title: 'MonoNoFuT_SamuraiPunks_Equip#004',
	desc: 'FloatingBits社情報局局長専用武器。',
	contract: '0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f',
	network: Network.MATIC_MAINNET,
	img: 'https://i.seadn.io/s/raw/files/776ebf381bdbdce7944c9b1c3ba421f6.png',
	shop:'https://opensea.io/assets/matic/0x1d03ae9b23c2d55a873e0c653c5c4b958c00993f/3',
	pfpimg:'https://i.seadn.io/s/raw/files/776ebf381bdbdce7944c9b1c3ba421f6.png',
	storyurl:'',
	company:'All',
	oneOn1:'',
	relate:[],
	type:NFTTYPE_EQUIP
}

];

export function getNFTContractList(network = Network.ETH_MAINNET) {
	// MONONOFU_CHARACTERSからcontractプロパティを抽出
	const contracts = MONONOFU_CHARACTERS.filter(character => character.network === network).map(character => character.contract);

	// contractsの配列から重複を削除
	const uniqueContracts = [...new Set(contracts)];

	return uniqueContracts;
}

