import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { useNavigate } from 'react-router-dom';
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress, resetOwnerStatus, getOwnerNFTStatus, getUserNFTOwningStatus, 
NFT_NOTOWN, NFT_CHAR_ONLY, NFT_RELATE_ONLY, NFT_CHAR_AND_RELATE, NFT_OWN_OTHERNFT} from './Session';
import { NFTTYPE_CHAR, NFTTYPE_PFP, NFTTYPE_EQUIP } from './resources/Data';



import Button from 'react-bootstrap/Button';

import { Card, Container, Row, Col } from 'react-bootstrap';

import {MONONOFU_CHARACTERS} from './resources/Data.js';      

import { PJOWNER } from './Utils';

import './css/decoration.css';

import classes from "./css/CharacterPage.module.css";
import btn from "./sass/button.scss";



import MModal from './Modal';
import OneOnOneModal from './OneOnOneModal';

import { useViewportScroll, useTransform, motion } from 'framer-motion';

import { useTranslation, Trans } from 'react-i18next';

function Characters() {
  const { t, i18n } = useTranslation();

  const address = getOwnerAddress();
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});

  const MODALTYPE_IMG = 0;
  const MODALTYPE_ONEONONE = 1;


  /* Secret Sectionを開くのに必要 */
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [modalType, setModalType] = useState(MODALTYPE_IMG);

  // カードをクリックしたときのハンドラ
  const handleCardClick = (card, modalType) => {
    setSelectedCard(card);
    setModalOpen(true);
    setModalType(modalType);
  };

  // モーダルを閉じるハンドラ
  const handleCloseModal = () => {
    setSelectedCard(null);
    setModalOpen(false);
    setModalType(MODALTYPE_IMG);
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden'; // モーダル表示時にスクロール禁止
    } else {
      document.body.style.overflow = ''; // モーダル非表示時にスクロール許可
    }

    return () => {
      document.body.style.overflow = ''; // コンポーネントがアンマウントされたときにスクロール許可
    };
  }, [modalOpen]);

  //characters画面でロック状態になった場合
  const navigate = useNavigate();
  useEffect(() => {
    //windows.ethereumのステータスが変更した場合の処理
    if (typeof window.ethereum !== 'undefined') {
        window.ethereum.on("accountsChanged", (accountNo) => {
          if(accountNo === 'undefined' || accountNo.length == 0) {
            resetOwnerStatus();
            dispatch({type: 'SET_ADDRESS', address: ''});
            handleCloseModal();
            navigate('/characters');
          }
        });
    }
  }, [address]);

  //表示する時はPFPは隠す
  const dispChars = MONONOFU_CHARACTERS.filter(character => (character.type === NFTTYPE_CHAR || character.type === NFTTYPE_EQUIP))
    //eq001を除外
    .filter(character => character.nftid !== 'eq001');



//ちょいバックアップ
//<Button className="btn btn-primary"  className={classes.button} onClick={() => window.open('/novel/index.html?nftid='+card.nftid, '_blank')}>1 on 1</Button>


  return (
    <>
    <div id="top">
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
    <div className={classes.top}>
      <h1>Characters</h1>
      <p>
        Cyborg List of Project monoNoFuT <br />
        ※1on1をプレイする場合最初の画面表示に暫くかかる場合がございます。
      </p>
    </div>
      </motion.div>

    <Container>

      <Row style={{ marginTop: '5px' }}>
        {dispChars.map((card) => (
          <Col key={card.nftid} md={4}>


            <Card style={{ marginTop: '10px' }}>
              <Card.Img variant="top" src={card.pfpimg} />
              <Card.Body>

                {getUserNFTOwningStatus(card) > 0 && (<div className="new-badge">{card.type === NFTTYPE_CHAR ? "Your Cyborg" : "Your Equip"}</div>)}

                <Card.Title><h2 className={classes.title}>{card.name}</h2></Card.Title>
                <Card.Text><p className={classes.sentence}>{card.desc}</p></Card.Text>

                <div>
                {/* <Button className="btn btn-primary" className="btn btn-primary" className={classes.button} onClick={() => window.open(card.shop, '_blank')}>MarketPlace</Button> */}
                {<button className={`pulse`} style={{width: '100%'}}  onClick={() => window.open(card.shop, '_blank')} >MarketPlace</button>}
                <br />

                {/*<Button className="btn btn-primary" className="btn btn-primary"  className={classes.button} onClick={() => window.open(card.storyurl, '_blank')}>Story</Button>*/}
                {(card.storyurl) && (
                  <>
                  <button className={`pulse`} style={{width: '100%'}}  onClick={() => window.open(card.storyurl, '_blank')} >Story</button>
                  <br />
                  </>
                )}
                

                
                {/* <Button  className={classes.button} onClick={() => handleCardClick(card, MODALTYPE_IMG)}>Secret Gallery</Button>*/}
                {getUserNFTOwningStatus(card) > 0 && (
                  <>
                  <button className={`pulse`} style={{width: '100%'}}  onClick={() => handleCardClick(card, MODALTYPE_IMG)} >Secret Gallery</button>
                  <br />
                  </>
                )}

                {/* {getUserNFTOwningStatus(card) > 0 && (
                  <button className={`pulse`}  style={{width: '100%'}} onClick={() => handleCardClick(card, MODALTYPE_ONEONONE)} >1 On 1</button>
                )}*/}
                {(card.oneOn1) && (
                  <button className={`pulse`}  style={{width: '100%'}} onClick={() => handleCardClick(card, MODALTYPE_ONEONONE)} hidden={card.type === NFTTYPE_EQUIP}>1 On 1</button>
                )}
                </div>

              </Card.Body>
            </Card>

          </Col>
        ))}
      </Row>

      {/* IMGモーダル */}
      {modalOpen && selectedCard && (modalType == MODALTYPE_IMG) && (
        <MModal isOpen={modalOpen} handleCloseModal={handleCloseModal} nft={selectedCard} />
      )}

      {/* 1 on 1 モーダル */}
      {modalOpen && selectedCard && (modalType == MODALTYPE_ONEONONE) && (
        <OneOnOneModal isOpen={modalOpen} handleCloseModal={handleCloseModal} nft={selectedCard} />
      )}
    </Container>

    </div>
    </>
  );
}

export default Characters;